/*global $*/
/*global Cookies*/
/*eslint no-undef: "error"*/

export default class Ref {
    classItem = 'ref--item'
    classButton = 'ref--button'
    cookieName = 'pansionaty-ref'
    cookieValue = '1'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = parseInt($(this).data('block'))

            if (block === 1) {
                document.body.innerHTML = ''
            }

            if (block === 2) {
                Cookies.set(self.cookieName, self.cookieValue, { expires: 1/96 })

                setTimeout(() => {
                    // let reloadUrl = window.location
                    // window.location = reloadUrl
                    window.location.reload(true)
                }, 500)
            }

            return false
        })
    }
}
